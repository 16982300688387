import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

export const ButtonStyled = styled(Button)`
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;

export const SpinnerStyled = styled(Spinner)`
  ${(props) =>
    props.size === "sm"
      ? `
        --bs-spinner-width: 1rem;
        --bs-spinner-height: 1rem;
    `
      : ""}
  ${(props) =>
    props.size === "md"
      ? `
        --bs-spinner-width: 1rem;
        --bs-spinner-height: 1rem;
    `
      : ""}
    ${(props) =>
    props.size === "lg"
      ? `
        --bs-spinner-width: 2rem;
        --bs-spinner-height: 2rem;
    `
      : ""}
`;
