import React from 'react';
import { FormFeedbackStyled, FormGroupStyled, FormLabelStyled, FormTextStyled, SelectStyled } from './styles';

export type TOptions = {
  label: string;
  value: string;
};

export type SelectProps = {
  label?: string;
  text?: string;
  feedback?: string;
  onChange?: (value: string) => void;
  classNameContainer?: string;
  isClearable?: boolean;
  options: TOptions[];
  placeholder?: string;
};

export default function Select({ label, text, feedback, classNameContainer, placeholder, ...rest }: SelectProps) {
  return (
    <FormGroupStyled className={classNameContainer}>
      {label && <FormLabelStyled>{label}</FormLabelStyled>}
      <SelectStyled {...rest} placeholder={placeholder || 'Selecione'} />
      {text && <FormTextStyled muted>{text}</FormTextStyled>}
      <FormFeedbackStyled type="invalid">{feedback || 'Campo obrigatório'}</FormFeedbackStyled>
    </FormGroupStyled>
  );
}
