import React from 'react'
import { ButtonStyled, SpinnerStyled } from './styles'
import {LoaderCircle} from 'lucide-react'
 
const Button = ({ loading, size = 'md', loadingText = null, ...props }) => (
    <ButtonStyled
        disabled={loading || props.disabled}
        size={size}
        {...props}
    >
        {loading && (
            <LoaderCircle className={"animate-spin text-white"} />
        )}
        {!loading ? props.children : (loadingText || 'Aguarde...')}
    </ButtonStyled>
)

export default Button