import React from 'react';
import BVUtils from '@bv/utils';
import * as singleSpa from 'single-spa'

export enum EPlanModules {
  'Triagem' = '1',
  'Teste de acuidade' = '2',
  'Teste de Ishihara' = '3',
  'Funcionalidade acesso ao médico do trabalho' = '4',
  'Desconto em óticas' = '5',
  'Concierge (suporte do time de Customer Success)' = '6',
  'Teleoftalmologia' = '7',
  'Plano de comunicação' = '8',
  'Conteúdo especial de saúde' = '9',
  'Consultório in company' = '10',
  'Renovação de receita de óculos' = '11',
}

type Props = {
  children: React.ReactNode;
  /**
   ** 1 Triagem
   ** 2 Teste de acuidade
   ** 3 Teste de Ishihara
   ** 4 Funcionalidade acesso ao médico do trabalho
   ** 5 Desconto em óticas
   ** 6 Concierge (suporte do time de Customer Success)
   ** 7 Teleoftalmologia
   ** 8 Plano de comunicação
   ** 9 Conteúdo especial de saúde
   ** 10 Consultório in company
   ** 11 Renovação de receita de óculos

   */
  requiredModules: Array<'1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11'>;
  isRouter?: boolean;
};
/**
 ** Componente de proteção
 ** Este componente protege qualquer componente filho,
 * garantindo que apenas usuários que tenha os modulos especificos poderão acessar o componente (filho).
 */
export default function ProtectedModule({ children, requiredModules = [], isRouter = false }: Props): React.ReactNode {
  const decodedToken = BVUtils.token.getDecodedToken();

  const userModulesIncluded = decodedToken?.modules || [];
  const hasRequiredModule: boolean = requiredModules.some((permission) => userModulesIncluded.includes(permission));

  if (!decodedToken) {
    return <></>;
  }

  // se for uma rota e nao tenho permissao para acessar
  if (isRouter && !hasRequiredModule) {
    singleSpa.navigateToUrl('/dashboard');
  }

  if (!decodedToken) {
    return <></>;
  }

  if (hasRequiredModule) {
    return <>{children}</>;
  }

  return <></>;
}
