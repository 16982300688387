import styled from 'styled-components';

import { CardProps } from './index';
import { devices } from '../../../../shared/styles/sizesMediaQueries';

export const Container = styled.div<CardProps>`
  width: ${(props) => props.width};
  display: flex;
  background-color: ${(props) => props.backgroundColor};
  padding: ${(props) => props.padding};
  flex-direction: ${(props) => props.flexDirection};
  gap: ${(props) => props.gap};
  border: ${(props) => props.border && '1px solid ' + props.borderColor};
  border-radius: ${(props) => props.borderRadius};
`;
