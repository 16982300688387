import React from 'react';
import DatePicker from 'react-datepicker';
import ptBr from 'date-fns/locale/pt-BR';
import 'react-datepicker/dist/react-datepicker.css';
import * as C from './styles';
import { Calendar } from 'react-bootstrap-icons';
import { PatternFormat } from 'react-number-format';
export type DatePickProps = {
  height?: string;
  minWidth?: string;
  label?: string;
  id?: string;
  selected?: Date | null;
  onChange?: (date: Date | null) => void;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  startDate?: Date | null;
  endDate?: Date | null;
  minDate?: Date | null;
  classNameContainer?: string;
  placeholder?: string;
  showError?: boolean;
  name?: string;
  showMask?: boolean;
  isClearable?: boolean;
};
const DatePick = ({
  height = '40px',
  label,
  id,
  endDate,
  minDate,
  onChange,
  selected,
  startDate,
  selectsEnd,
  selectsStart = false,
  classNameContainer = '',
  minWidth = '240px',
  placeholder = 'Selecione uma data',
  showError = false,
  name = undefined,
  showMask = true,
  isClearable = false,
}: DatePickProps) => {
  return (
    <C.Container height={height} className={classNameContainer} minWidth={minWidth} showError={showError}>
      {label && <label htmlFor={id}>{label}</label>}
      <div className="date-row">
        <DatePicker
          selected={selected}
          startDate={startDate}
          endDate={endDate}
          onChange={(date) => onChange && onChange(date)}
          placeholderText={placeholder}
          locale={ptBr}
          dateFormat="dd/MM/yyyy"
          className="form-control"
          id={id}
          selectsStart={selectsStart}
          selectsEnd={selectsEnd}
          minDate={minDate}
          name={name}
          clearButtonTitle="Limpar"
          customInput={showMask ? <PatternFormat format="##/##/####" mask="_" /> : null}
          isClearable={isClearable}
        />

        <div className="date-row__icon">
          <Calendar />
        </div>
      </div>
    </C.Container>
  );
};

export default DatePick;
