import styled from "styled-components"

export const Container = styled.div<{ color: string; backgroundColor: string }>`
  width: max-content;
  border-radius: 16px;
  background-color: ${(props) => props.backgroundColor};

  padding: 4px 8px;
  min-width: 73px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: ${(props) => props.color};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`
