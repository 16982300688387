import { Modal } from "react-bootstrap";
import BVUtilsOld from "@bv/utils-old";
const { colors } = BVUtilsOld;
import styled from "styled-components";
import { devices } from "../../../../../shared/styles/sizesMediaQueries";

export const ModaContainer = styled(Modal)`
  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 150%; /* 24px */
  }

  p {
    color: ${colors.titleColor};
  }

  width: 100%;

  @media ${devices.mobileXL} {
    .row-buttons {
      flex-direction: column-reverse !important;
      padding-bottom: 0 !important;
      gap: 12px !important;
      button {
        text-align: center;
        justify-content: center;
      }
    }
  }
`;
