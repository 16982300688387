import styled from 'styled-components'
import AsyncSelect from 'react-select/async'
import Form from 'react-bootstrap/Form'
import BVUtilsOld from '@bv/utils-old'
const { colors } = BVUtilsOld

export const FormGroupStyled = styled(Form.Group)`
    padding-bottom: 20px;
`;

export const FormLabelStyled = styled(Form.Label)`
    font-size: 0.9rem;
    font-weight: 500;
    color: ${colors.darkBlue};
`;

export const AsyncSelectStyled = styled(AsyncSelect)``

export const FormTextStyled = styled(Form.Text)``;

export const FormFeedbackStyled = styled(Form.Control.Feedback)``;