import React from 'react'
import { PaginationStyled, PaginationItemStyled } from './styles'

const Pagination = ({ active = 1, count = 1, onChange, ...props }) => (
    <PaginationStyled 
        size='sm'
        {...props}
    >
        {Array(count).fill('').map((el, index) => (
            <PaginationItemStyled 
                key={index}
                active={active == (index + 1)}
                onClick={() => onChange && onChange((index + 1))}
            >
                {index+1}
            </PaginationItemStyled>
        ))}
    </PaginationStyled>
)

export default Pagination