import ReactSelect from "react-select";
import AsyncSelect from "react-select/async";

import BVUtilsOld from "@bv/utils-old";
const { colors } = BVUtilsOld;
import styled, { css } from "styled-components";
import { ReactSelectProps } from ".";
import searchIcon from "../../../assets/icons/search.svg";

const allStyleSelect = css<ReactSelectProps>`
  min-width: ${(props) => props.minWidth};
  max-width: ${(props) => props.maxWidth};
  height: ${({ height }) => height};

  .react-select-custom__control {
    height: 100%;
    border: 1px solid ${(props) => props.borderColor};
  }

  .react-select-custom__value-container {
    padding-left: 16px;
  }

  ${({ showSearchIcon }) => showSearchIcon && styleSearchIcon}

  @media (max-width: 600px) {
    width: 100%;
  }
`;

// apenas se showIconSearch estiver true
const styleSearchIcon = css`
  .react-select-custom__value-container {
    position: relative;
    gap: 10px;
    &::before {
      content: "";
      background: url(${searchIcon});
      width: 16px;
      height: 16px;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;

      position: absolute;
      left: 16px;
    }
  }

  .react-select-custom__value-container {
    padding-left: 42px;
    text-wrap: nowrap;
  }

  .react-select-custom__placeholder {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const Select = styled(ReactSelect)<ReactSelectProps>`
  ${allStyleSelect};
`;

export const AsyncReactSelect = styled(AsyncSelect)<ReactSelectProps>`
  ${allStyleSelect};
`;

export const Container = styled.div<ReactSelectProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  width: ${({ widthContainer }) => widthContainer};

  label {
    color: ${colors.neutralDarkest};
    font-size: 12px;
    font-weight: 700;
    line-height: 150%;
    width: max-content;
  }
`;
