import styled from "styled-components"
import Form from "react-bootstrap/Form"

export const Container = styled(Form.Group)`
  padding-bottom: 0px;
  flex: 1;

  .eye-icon {
    padding-left: 16px;
  }

  label {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    color: #000;
  }
`
