import React, { useCallback } from 'react';
import debounce from 'lodash.debounce';
import { FormGroupStyled, FormLabelStyled, FormControlStyled, FormTextStyled, FormFeedbackStyled, FormSelectStyled, FormCheckStyled } from './styles';

import eyeAsset from '../../assets/icons/eye.svg';
import eyeClosedAsset from '../../assets/icons/eye-closed.svg';

export type FormProps = {
  id?: string;
  required?: boolean;
  type?: 'text' | 'select' | 'radio' | 'checkbox' | 'switch' | 'password';
  showPassword?: boolean;
  setShowPassword?: (value: boolean) => void;
  isPasswordInput?: boolean;
  text?: string;
  feedback?: string;
  debounceChange?: any;
  inputRef?: any;
  label?: string;
  name?: string;
  value: any;
  onChange?: (event: any) => void;
  classNameContainer?: string;
  checked?: boolean;
  debounce?: boolean;
  noValidate?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;
const Form = ({
  type = 'text',
  text,
  showPassword,
  setShowPassword,
  isPasswordInput = false,
  feedback,
  debounceChange,
  inputRef,
  classNameContainer = '',
  ...props
}: FormProps) => {
  function handleChange(el) {
    props.onChange && props.onChange(el);
    props.debounce && _debounceChange(el.target.value);
  }

  const _debounceChange = useCallback(
    debounce((value: any) => {
      debounceChange && debounceChange(value);
    }, props.debounce || 0),
    [],
  );

  return (
    <>
      <FormGroupStyled className={classNameContainer} isPasswordInput={isPasswordInput}>
        {props.label && !['radio', 'checkbox', 'switch'].includes(type) && <FormLabelStyled htmlFor={props.id}>{props.label}</FormLabelStyled>}
        {!['textarea', 'select', 'radio', 'checkbox', 'switch', 'password'].includes(type) && !isPasswordInput && (
          <FormControlStyled {...props} type={type} onChange={handleChange} />
        )}
        {['password'].includes(type) && setShowPassword && isPasswordInput && (
          <>
            <FormControlStyled {...props} type={showPassword ? 'text' : 'password'} isPasswordInput={isPasswordInput} onChange={handleChange} />
            <button type="button" onClick={() => setShowPassword(!showPassword)} className="btn__eyes">
              <img src={showPassword ? eyeAsset : eyeClosedAsset} width={24} height={24} />
            </button>
          </>
        )}
        {['textarea'].includes(type) && !isPasswordInput && <FormControlStyled {...props} type={type} as={type} />}
        {type === 'select' && (
          <FormSelectStyled {...props} type={type}>
            {props.children}
          </FormSelectStyled>
        )}
        {['radio', 'checkbox', 'switch'].includes(type) && !isPasswordInput && <FormCheckStyled {...props} type={type} />}
        {text && <FormTextStyled muted>{text}</FormTextStyled>}

        <FormFeedbackStyled type="invalid">{feedback || 'Campo obrigatório'}</FormFeedbackStyled>
      </FormGroupStyled>
    </>
  );
};

export default Form;
