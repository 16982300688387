import React from 'react'
import { 
    FormLabelStyled,
    FormRangStyled,
 } from './styles'

 function Range({ label, ...props }) {
   return (
     <>
        {label && (
            <FormLabelStyled>{label}</FormLabelStyled>
        )}
        <FormRangStyled {...props} />
     </>
   );
 }
 
 export default Range