import React, { useRef } from 'react';
import styled from 'styled-components';

interface IProps {
  children: React.ReactNode;
  open: boolean;
  /**
   * restHeight => Uma sobra em px, caso tenha padding
   */
  restHeight?: number;
  className?: string;
}

function Accordion({ children, open, restHeight, className = '', ...rest }: IProps & React.HTMLAttributes<HTMLSpanElement>) {
  const ref = useRef<HTMLHeadingElement>(null);
  const heightRest = restHeight ? restHeight : 0;
  const currentScrollHeight = ref?.current?.scrollHeight;

  return (
    <Container
      open={open}
      ref={ref}
      className={className}
      style={{
        maxHeight: open ? currentScrollHeight! + heightRest || '100vh' : 0,
      }}
      {...rest}>
      {children}
    </Container>
  );
}

export default Accordion;

const Container = styled.div<{ open: boolean }>`
  overflow: hidden;
  transition: 0.2s;
`;
