import styled from 'styled-components'
import Form from 'react-bootstrap/Form'
import BVUtilsOld from '@bv/utils-old'
const { colors } = BVUtilsOld

export const FormLabelStyled = styled(Form.Label)`
    font-size: 0.9rem;
    font-weight: 500;
    color: ${colors.darkBlue};
`;

export const FormRangStyled = styled(Form.Range)`
    width: 100%;
`;

