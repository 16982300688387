import React from 'react';
import { ContainerButton } from './styles';
import { LoaderCircle } from 'lucide-react';

type ButtonProps = {
  children: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  textLoading?: string;
  showSpinner?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;
export default function ButtonLoading({ disabled, loading, textLoading = 'Aguarde', showSpinner = true, children, ...rest }: ButtonProps) {
  return (
    <ContainerButton {...rest} disabled={disabled}>
      {loading && showSpinner && <LoaderCircle size={18} className={'animate-spin text-white'} />}
      {!loading ? children : textLoading}
    </ContainerButton>
  );
}
