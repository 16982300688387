import styled from "styled-components";
import BVUtilsOld from "@bv/utils-old";
const { colors } = BVUtilsOld;
import { DatePickProps } from ".";

export const Container = styled.div<DatePickProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: ${({ minWidth }) => minWidth};

  label {
    color: #000;
    font-size: 12px;
    font-weight: 700;
    line-height: 150%; /* 18px */
  }

  .date-row {
    display: flex;
    align-items: center;
    /* height: 40px; */
    height: ${({ height }) => height};

    .react-datepicker-wrapper,
    .react-datepicker__input-container {
      height: 100%;
    }

    .react-datepicker-wrapper {
      flex: 1;
    }

    input {
      height: 100%;
      border-radius: 4px 0 0 4px;
      border-right: 0;
      ${({ showError }) => showError && "border: 1px solid #dc3545;"};

      &::placeholder {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        color: ${colors.neutralLight};
      }
    }
  }

  .date-row__icon {
    display: flex;
    padding: 12px;
    align-items: center;
    justify-content: center;
    border-radius: 0px 4px 4px 0px;
    background: ${colors.negativePure};
  }
`;
