import React from 'react';
import styled from 'styled-components';
type Props = {
  title?: string;
};
export default function ToastLoadingTable({ title = 'Atualizando ...' }: Props) {
  return <Container>{title}</Container>;
}

const Container = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 0px 10px;
  background: rgb(244 180 0 / 100%);
  border: 1px solid rgb(244 180 0);
  font-weight: 500;
`;
