import styled from "styled-components";
import BVUtilsOld from "@bv/utils-old";
const { colors } = BVUtilsOld;
import { TAvatarSize, AvatarProps } from ".";

const AVATAR_SIZE = {
  sm: "40px",
  md: "64px",
  lg: "112px",
};

const AVATAR_TEXT_SIZE = {
  sm: "40px",
  md: "64px",
  lg: "112px",
};

export const AvatarBox = styled.div<AvatarProps>`
  min-width: ${(props) => AVATAR_SIZE[props.size] ?? AVATAR_SIZE.md};
  min-height: ${(props) => AVATAR_SIZE[props.size] ?? AVATAR_SIZE.md};
  max-width: ${(props) => AVATAR_SIZE[props.size] ?? AVATAR_SIZE.md};
  max-height: ${(props) => AVATAR_SIZE[props.size] ?? AVATAR_SIZE.md};
  border-radius: 50%;
  background-color: ${(props) => props.backgroundColor};

  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;

  span {
    /* color: #fff; */
    color: ${(props) => props.textColor};
    font-size: ${(props) =>
      `calc(${AVATAR_TEXT_SIZE[props.size] ?? AVATAR_TEXT_SIZE.md} * 0.4)`};
  }
`;
