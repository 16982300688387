import { useEffect, useState } from 'react';
import BvStyleguide from '@bv/styleguide';

export default function Layout({ children }: { children: React.ReactNode }) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    const handleMenuCollapse = (event: CustomEvent) => {
      setIsCollapsed(event.detail.isCollapsed);
    };

    window.addEventListener('menuCollapsed', handleMenuCollapse);

    return () => {
      window.removeEventListener('menuCollapsed', handleMenuCollapse);
    };
  }, []);

  return (
    <div className="h-screen overflow-hidden">
      <div className={BvStyleguide.cn('flex h-full flex-col max-[768px]:mt-16 max-[768px]:p-2 md:p-8', 'transition-all duration-300 ease-in-out')}>
        {children}
      </div>
    </div>
  );
}
