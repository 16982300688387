import React from 'react';

import * as C from './styles';

export type TOptions = {
  value: string;
  label: string;
};

export type ReactSelectProps = {
  onChange?: (option: TOptions) => void;
  options?: TOptions[];
  placeholder?: string;
  label?: string;
  className?: string;
  classNameContainer?: string;
  height?: string;
  widthContainer?: string;
  isSearchable?: boolean;
  showSearchIcon?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isClearable?: boolean;
  inputId?: string;
  noOptionsMessage?: () => string | React.ReactNode;
  loadingMessage?: () => string | React.ReactNode;
  isAsync?: boolean;
  loadOptions?: (inputValue: string) => Promise<TOptions[]>;
  autoFocus?: boolean;
  openMenuOnFocus?: boolean;
  value?: TOptions;
  name?: string;
  onBlur?: () => void;
  ref?: React.Ref<any>;
  borderColor?: string;
  showError?: boolean;
  onInputChange?: (inputValue: string) => void;
  cacheOptions?: boolean;
  defaultOptions?: boolean | TOptions[];
  isMulti?: boolean;
  defaultValue?: TOptions;
  minWidth?: string;
  maxWidth?: string;
  formatOptionLabel?: (data: TOptions, ctx: any) => React.ReactNode;
  controlShouldRenderValue?: boolean;
};

function ReactSelect({
  onChange,
  options,
  placeholder = 'Selecione...',
  label = '',
  height = '40px',
  isSearchable = true,
  showSearchIcon,
  isDisabled = false,
  isLoading = false,
  isClearable = false,
  widthContainer = '100%',
  inputId = label,
  noOptionsMessage = () => 'Nenhum resultado',
  loadingMessage = () => 'Carregando...',
  isAsync = false,
  loadOptions,
  autoFocus = false,
  openMenuOnFocus = true,
  value,
  name,
  onBlur,
  ref,
  borderColor = 'hsl(0, 0%, 80%)',
  showError,
  onInputChange,
  cacheOptions,
  defaultOptions,
  classNameContainer = '',
  isMulti = false,
  defaultValue,
  minWidth = '240px',
  maxWidth = 'none',
  formatOptionLabel = undefined,
  controlShouldRenderValue = true,
}: ReactSelectProps) {
  const _borderColor = !showError ? borderColor : '#dc3545';
  const Select = (
    <C.Select
      isDisabled={isDisabled}
      isLoading={isLoading}
      isClearable={isClearable}
      isSearchable={isSearchable}
      onChange={(option: TOptions) => {
        onChange && onChange(option);
      }}
      options={options}
      placeholder={placeholder}
      classNamePrefix="react-select-custom"
      height={height}
      borderColor={_borderColor}
      showSearchIcon={showSearchIcon}
      inputId={inputId}
      noOptionsMessage={noOptionsMessage}
      loadOptions={loadOptions}
      autoFocus={autoFocus}
      openMenuOnFocus={openMenuOnFocus}
      value={value}
      name={name}
      onBlur={onBlur}
      ref={ref && ref}
      loadingMessage={loadingMessage}
      onInputChange={(text: string) => {
        onInputChange && onInputChange(text);
      }}
      isMulti={isMulti}
      defaultValue={defaultValue}
      minWidth={minWidth}
      maxWidth={maxWidth}
      formatOptionLabel={formatOptionLabel}
      controlShouldRenderValue={controlShouldRenderValue}
    />
  );
  const extendedProps = Select.props;

  const AsyncSelect = <C.AsyncReactSelect {...extendedProps} cacheOptions={cacheOptions} defaultOptions={defaultOptions} />;

  return (
    <C.Container widthContainer={widthContainer} className={classNameContainer}>
      {label && <label htmlFor={inputId}>{label}</label>}
      {isAsync ? AsyncSelect : Select}
    </C.Container>
  );
}

export default ReactSelect;
