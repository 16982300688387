import React from 'react';
import { AvatarBox } from './styles';
import BVUtilsOld from '@bv/utils-old';
const { colors } = BVUtilsOld;

export type TAvatarSize = 'sm' | 'md' | 'lg';

export type AvatarProps = {
  userName?: string;
  size?: TAvatarSize;
  className?: string;
  backgroundColor?: string;
  textColor?: string;
};
const Avatar = (props: AvatarProps) => {
  const { userName = '-', size = 'sm' } = props;

  let nameSplited = userName
    .trim()
    .replace(/ d?[aeo]s? /, ' ')
    .split(' ');

  if (nameSplited.length > 2) {
    nameSplited = [nameSplited[0], nameSplited[nameSplited.length - 1]];
  }

  const abbreviation = nameSplited
    .map((word) => word[0])
    .join('')
    .toUpperCase();

  return (
    <AvatarBox
      size={size}
      className={props?.className || ''}
      backgroundColor={props?.backgroundColor || colors.primaryColor}
      textColor={props?.textColor || 'white'}>
      <span>{abbreviation}</span>
    </AvatarBox>
  );
};

export default Avatar;
