export const devices = {
  mobileS: `(max-width: 320px)`,
  mobileM: `(max-width: 375px )`,
  mobileL: `(max-width: 425px)`,
  mobileXL: `(max-width: 600px)`,
  tablet: `(max-width: 768px)`,
  laptop: `(max-width:  1024px)`,
  laptopL: `(max-width: 1440px)`,
  desktop: `(max-width: 2560px)`
};
