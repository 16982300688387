import React from 'react';
import * as C from './styles';
import Button from '../Button';
import * as singleSpa from 'single-spa';

type BreadCrumbProps = {
  currentPage: string;
  previousPages: Array<{
    name: string;
    url: string;
  }>;
  textGoBack?: string;
  showGoBack?: boolean;
};
export default function BreadCrumb({ currentPage, previousPages = [], textGoBack = 'Voltar', showGoBack = true }: BreadCrumbProps) {
  // const navigate = useNavigate()

  return (
    <C.Container>
      <div>
        {previousPages.map((item, index) => (
          <React.Fragment key={index}>
            <Button variant="default" onClick={() => singleSpa.navigateToUrl(item.url)}>
              {item.name}
            </Button>
            <small>/</small>
          </React.Fragment>
        ))}
        <span>{currentPage}</span>
      </div>

      {/* {showGoBack && (
        <button onClick={() => navigate(-1)} type="button">
          {textGoBack}
        </button>
      )} */}
    </C.Container>
  );
}
