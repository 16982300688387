import React from 'react'
import { 
    FormGroupStyled, 
    FormLabelStyled, 
    CreatableSelectStyled,
    FormTextStyled,
    FormFeedbackStyled
} from './styles'

export default ({ label, text, feedback, ...props }) => (
    <FormGroupStyled>
        {label && (
            <FormLabelStyled>{label}</FormLabelStyled>
        )}
        <CreatableSelectStyled {...props} />
        {text && (
            <FormTextStyled muted>{text}</FormTextStyled>
        )}
        <FormFeedbackStyled type="invalid">{feedback || 'Campo obrigatório'}</FormFeedbackStyled>
    </FormGroupStyled>
)