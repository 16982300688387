import React from 'react';
import * as C from './styles';
import { Search } from 'react-bootstrap-icons';

export interface InputFieldProps {
  children: React.JSX.Element;
  showError?: boolean;
  erroMessage?: string;
  label?: string;
  id?: string;
  showIcon?: boolean;
  borderColor?: string;
  minWidth?: string;
  height?: string;
  Icon?: React.ReactNode;
  className?: string;
  isOptional?: boolean;
  classNameLabel?: string;
  classNameInput?: string;
}

function InputField({
  children,
  showError = false,
  erroMessage = '',
  label = '',
  id = label,
  showIcon = false,
  borderColor = 'hsl(0, 0%, 80%)',
  minWidth = '240px',
  height = '40px',
  Icon = <Search />,
  className = '',
  isOptional = false,
  classNameLabel = '',
  classNameInput = '',
}: InputFieldProps) {
  const _borderColor = !showError ? borderColor : '#dc3545';

  const Input = React.cloneElement(children, { id: id });

  return (
    <C.Container showError={showError} borderColor={_borderColor} showIcon={showIcon} minWidth={minWidth} height={height} className={className}>
      <label htmlFor={id} className={'form-label ' + classNameLabel}>
        {label} {isOptional && <span className="if-optional-label">(opcional)</span>}
      </label>

      <div className={'if-input-row ' + classNameInput}>
        {Input}

        {showIcon && <div className="if-icon-search">{Icon}</div>}
      </div>

      {erroMessage && (
        <div className="erro__container">
          <span>{String(erroMessage)}</span>
        </div>
      )}
    </C.Container>
  );
}

export default InputField;
