import React, { useState } from 'react';
import BVStyleguide from '@bv/styleguide';
import { Modal } from 'react-bootstrap';
import * as C from './styles';
import { Info } from 'lucide-react';
import * as singleSpa from 'single-spa';

type TResponsibilityWarnModalProps = {
  action?: () => void;
};

function ResponsibilityWarnModal({ action }: TResponsibilityWarnModalProps) {
  const [show, setShow] = useState(true);

  const closeWarnModal = () => {
    setShow(false);
    if (action) action();
  };

  return (
    <BVStyleguide.Dialog open={show}>
      <BVStyleguide.DialogContent className="sm:max-w-[500px]">
        <BVStyleguide.DialogHeader>
          <BVStyleguide.DialogTitle>Antes de prosseguir</BVStyleguide.DialogTitle>
          <BVStyleguide.DialogDescription>Você deve concordar com os seguintes termos:</BVStyleguide.DialogDescription>
        </BVStyleguide.DialogHeader>
        <ul className="list-disc pl-4">
          <li className="text-muted-foreground mb-2 text-sm">
            Os testes fornecidos pelo Benefício Visão são de uso exclusivo de seus usuários. Os dados de login e acesso não devem ser compartilhados
            com terceiros.
          </li>
          <li className="text-muted-foreground text-sm">
            Ao realizar os testes, você assume responsabilidade pela veracidade das informações de saúde.
          </li>
        </ul>
        <BVStyleguide.DialogFooter>
          <BVStyleguide.Button onClick={() => singleSpa.navigateToUrl('/dashboard')} variant="ghost">
            Não concordo
          </BVStyleguide.Button>
          <BVStyleguide.Button onClick={closeWarnModal}>Estou de acordo</BVStyleguide.Button>
        </BVStyleguide.DialogFooter>
      </BVStyleguide.DialogContent>
    </BVStyleguide.Dialog>
  );
}
export default React.memo(ResponsibilityWarnModal);
