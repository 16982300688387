import styled, { css } from "styled-components";
import BVUtilsOld from "@bv/utils-old";
const { colors } = BVUtilsOld;

// apenas se showIcon estiver true
const showIconStyles = css`
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const Container = styled.div<{
  showError: boolean;
  showIcon: boolean;
  borderColor: string;
  minWidth: string;
  height: string;
}>`
  position: relative;
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-width: ${(props) => props.minWidth};
  flex: 1;
  gap: 8px;

  input {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 8px 16px;
    border-radius: 4px;
    background-clip: padding-box;

    border: 1px solid ${({ borderColor }) => borderColor};
    appearance: none;
    /* border-radius: 10.375rem; */
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    ::placeholder {
    }

    :focus {
      color: #212529;
      background-color: #fff;
      border-color: ${({ showError, borderColor }) =>
        !showError ? "#2684FF" : borderColor};
      outline: 0;

      box-shadow: ${({ showError }) =>
        !showError ? "0 0 0 1px #2684FF" : "0 0 0 1px rgba(220,53,69,.25)"};
    }
  }

  .erro__container {
    display: flex;
    position: absolute;
    bottom: -20px;
    span {
      font-size: 0.875em;
      color: #dc3545;
    }
  }

  label {
    font-size: 12px;
    font-weight: 700;
    line-height: 150%; /* 18px */
    color: ${colors.neutralDarkest};
    margin: 0;
  }

  .if-input-row {
    /* background-color: red; */
    width: 100%;
    display: flex;
    align-items: center;
    height: ${(props) => props.height};
  }

  .if-icon-search {
    background-color: #eaebeb;
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 0px 4px 4px 0px;
  }

  .if-optional-label {
    color: #838384;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }

  ${(props) => props.showIcon && showIconStyles}
`;
