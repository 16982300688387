import React from 'react';
import { Modal } from 'react-bootstrap';
import { ModaContainer } from './styles';
import ButtonLoading from '../../buttons/button-loading';
import { Info } from 'lucide-react';

type IConfirmModalProps = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  description: string;
  textCancel?: string;
  textConfirm?: string;

  onCancel?: () => void;
  onConfirm?: () => void;
  loading?: boolean;
  title?: string;
};

function ConfirmModal({
  show,
  description,
  textCancel = 'Cancelar',
  textConfirm = 'Confirmar',
  onCancel,
  setShow,
  onConfirm,
  loading,
  title = 'Cancelar',
}: IConfirmModalProps) {
  return (
    <ModaContainer aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton className="border-1 pb-1">
        <h2> {title}</h2>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col items-center justify-center">
          <Info width={164} height={130} />

          <p className="my-4 text-center">{description}</p>

          <div className="w-75 row-buttons flex flex-row justify-end gap-4 pb-4">
            <button
              className="btn btn-link text-primary ml-auto"
              onClick={() => {
                onCancel && onCancel();
              }}>
              {textCancel}
            </button>
            <ButtonLoading
              loading={loading}
              disabled={loading}
              className="btn btn-primary"
              onClick={() => {
                onConfirm && onConfirm();
              }}>
              {textConfirm}
            </ButtonLoading>
          </div>
        </div>
      </Modal.Body>
    </ModaContainer>
  );
}

export default ConfirmModal;
