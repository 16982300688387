import styled from 'styled-components'
import Form from 'react-bootstrap/Form'
import BVUtilsOld from '@bv/utils-old'
const { colors } = BVUtilsOld


export const FormStyled = styled(Form)`
    
`;

export const FormGroupStyled = styled(Form.Group)`
    padding-bottom: 20px;
`;

export const FormLabelStyled = styled(Form.Label)`
    font-size: 0.9rem;
    font-weight: 500;
    color: ${colors.darkBlue};
`;

export const FormControlStyled = styled(Form.Control)`
    width: 100%;
    color: #333333;
`;

export const FormTextStyled = styled(Form.Text)`

`;

export const FormFeedbackStyled = styled(Form.Control.Feedback)`

`;

export const FormSelectStyled = styled(Form.Select)`

`;

export const FormCheckStyled = styled(Form.Check)`

`;

