import React from "react"

export default function Eye() {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 8.5C10.5 9.88071 9.38071 11 8 11C6.61929 11 5.5 9.88071 5.5 8.5C5.5 7.11929 6.61929 6 8 6C9.38071 6 10.5 7.11929 10.5 8.5Z"
        fill="#838384"
      />
      <path
        d="M0 8.5C0 8.5 3 3 8 3C13 3 16 8.5 16 8.5C16 8.5 13 14 8 14C3 14 0 8.5 0 8.5ZM8 12C9.933 12 11.5 10.433 11.5 8.5C11.5 6.567 9.933 5 8 5C6.067 5 4.5 6.567 4.5 8.5C4.5 10.433 6.067 12 8 12Z"
        fill="#838384"
      />
    </svg>
  )
}
