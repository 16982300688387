import React, { useCallback } from 'react';
import debounce from 'lodash.debounce';
import Select, { components, ControlProps, Props, StylesConfig } from 'react-select';
import * as C from './styles';
import LeftEye from '../../assets/images/Eye';
import { TOptions } from '../../../../shared/interfaces/form.interface';

export type PrescriptionButtonProps = {
  label?: string;
  id?: string;
  onChange?: (el: any) => void;
  placeholder?: string;
  eyelabel?: string;
  options?: TOptions[];
  name?: string;
  menuPlacement?: 'bottom' | 'top' | 'auto';
};

const PrescriptionButton = ({ label, id, onChange, placeholder, eyelabel, options, name = '', menuPlacement = 'auto' }: PrescriptionButtonProps) => {
  function handleChange(option: TOptions) {
    onChange && onChange(option);
  }

  return (
    <>
      <C.Container>
        {eyelabel}
        {label && <label htmlFor={id}>{label}</label>}

        <Select
          menuPlacement={menuPlacement}
          maxMenuHeight={160}
          classNamePrefix="select-prescription"
          components={{ Control }}
          isSearchable
          name={name}
          placeholder={placeholder}
          options={options}
          onChange={(option) => {
            handleChange(option);
          }}
        />
      </C.Container>
    </>
  );
};

const Control = ({ children, ...props }: { children: React.ReactNode }) => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <components.Control {...props}>
      <span className="eye-icon">
        <LeftEye />
      </span>
      {children}
    </components.Control>
  );
};

export default PrescriptionButton;
