import React, { useState } from 'react'
import { ContainerStyled } from './styles'

const IconButton = (props) => {


    return (
        <ContainerStyled {...props}>
            {props.icon}
        </ContainerStyled>
    )
}

export default IconButton