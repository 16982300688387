import React, { useCallback, useEffect, useMemo } from 'react';
import { Check, ExclamationCircle, XCircle } from 'react-bootstrap-icons';
import BVUtilsOld from '@bv/utils-old';
const { colors } = BVUtilsOld;
import { Alert } from 'react-bootstrap';

export type TFlashMessage = {
  flashMessage: {
    message: string;
    variant?: 'success' | 'danger' | 'warning' | 'info';
  };
};

interface FlashMessageProps {
  location: any;
  navigate: any;
}
export default function FlashMessage({ location, navigate }: FlashMessageProps) {
  const state = location.state as TFlashMessage;
  const resetNavigationState = useCallback(() => {
    navigate(location.pathname, { replace: true });
  }, [location.pathname, navigate]);

  useEffect(() => {
    let timer = undefined;
    if (state?.flashMessage?.message) {
      document.querySelector('#main-content-outlet')?.scrollTo({ top: 0, behavior: 'instant' });
      timer = setTimeout(() => {
        resetNavigationState();
      }, 8000);
    }

    return () => clearTimeout(timer);
  }, [state?.flashMessage?.message, resetNavigationState]);

  const message: string = useMemo(() => state?.flashMessage?.message, [state]);
  const variant = useMemo(() => state?.flashMessage?.variant || 'success', [state]);
  const textColor: string = useMemo(() => {
    switch (variant) {
      case 'success':
        return colors.greenDark;
      case 'danger':
        return 'falta implementar';
      case 'warning':
        return '#E16600';
      case 'info':
        return 'falta implementar';
      default:
        return colors.greenDark;
    }
  }, [variant]);

  if (!message) return <></>;

  return (
    <Alert show={!!message} variant={variant} className="mb-4 flex items-center justify-between p-2">
      <div className="flex items-center gap-3">
        {variant == 'success' ? <Check width={16} height={16} color={textColor} /> : <ExclamationCircle color={textColor} />}

        {/* <span className="text-green-dark">{message}</span> */}
        <span
          style={{
            color: textColor,
          }}>
          {message}
        </span>
      </div>

      <button onClick={resetNavigationState}>
        <XCircle color={textColor} />
      </button>
    </Alert>
  );
}
