import styled, { css } from 'styled-components'
import Form from 'react-bootstrap/Form'
import BVUtilsOld from '@bv/utils-old'
const { colors } = BVUtilsOld

export const FormStyled = styled(Form)`
`;

export const FormGroupStyled = styled(Form.Group)`
    padding-bottom: 20px;

    ${props => props.isPasswordInput && css`
        position: relative;
    `}
 
    button {
        position: absolute;
        top: 0;
        right: 16px;
        right: ${({ theme, showError }) => (showError ? "40px" : "16px")};
        display: flex;
        align-items: center;
        transform: translate(0, 50%);
        top: 24px;
        right: 8px;
    }
`;

export const FormLabelStyled = styled(Form.Label)`
    font-size: 0.9rem;
    font-weight: 500;
    color: ${colors.darkBlue};
`;

export const FormControlStyled = styled(Form.Control)`
    width: 100%;
    color: #333333;

    ${props => props.isPasswordInput && css`
        background-image: none !important;
    `}
`;

export const FormTextStyled = styled(Form.Text)`
`;

export const FormFeedbackStyled = styled(Form.Control.Feedback)`
`;

export const FormSelectStyled = styled(Form.Select)`
`;

export const FormCheckStyled = styled(Form.Check)`

`;

