import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

type TSpinnerProps = {
  variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
  animation?: 'border' | 'grow';
  color?: string;
};

const colorDefault = '#2845DC';

function LoadingBox({ variant = 'primary', animation = 'grow', color = colorDefault }: TSpinnerProps) {
  return (
    <div className="h-75 flex items-center justify-center">
      <Spinner animation={animation} variant={variant} style={{ background: color, animationDuration: '0.7s' }} />
    </div>
  );
}

export default React.memo(LoadingBox);
