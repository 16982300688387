import React from 'react';
import { Container } from './styles';
export type TBadgeProps = {
  text?: string;
  variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
};

const _COLORS = {
  success: {
    background: '#D9F9E0',
    color: '#128729',
  },
  danger: {
    background: '#F2A9CC',
    color: '#59012A',
  },
  secondary: {
    background: '#D9D9D9',
    color: '#2b2f32',
  },
  primary: {
    background: '#B2DDFF',
    color: '#052c65',
  },
};

export default function Badge({ text = 'Badge', variant = 'success' }: TBadgeProps) {
  const { background, color } = _COLORS[variant];

  return (
    <Container backgroundColor={background} color={color}>
      <span>{text}</span>
    </Container>
  );
}
