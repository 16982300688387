import React from 'react';
import { Container } from './styles';

export const PADDING_CARD = '32px';

export type CardProps = {
  children: React.ReactNode;
  borderColor?: string;
  backgroundColor?: string;
  width?: string;
  height?: string;
  borderRadius?: string;
  border?: boolean;
  gap?: string;
  padding?: string;
  className?: string;
  flexDirection?: 'column' | 'row';
};
const Card = ({
  width,
  height,
  borderRadius = '8px',
  borderColor = '#EAEBEB',
  backgroundColor = '#fff',
  border,
  gap = PADDING_CARD,
  padding = PADDING_CARD,
  children,
  className = '',
  flexDirection = 'column',
}: CardProps) => {
  return (
    <Container
      width={width}
      height={height}
      borderRadius={borderRadius}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      border={border}
      gap={gap}
      padding={padding}
      className={className}
      flexDirection={flexDirection}>
      {children}
    </Container>
  );
};

export default Card;
