import 'bootstrap/dist/css/bootstrap.min.css';

import AsyncSelect from './components/AsyncSelect';
import Accordion from './components/accordion';
import Avatar from './components/Avatar';
import Button from './components/Button';
import BreadCrumb from './components/breadcrumb';
import Card from './components/card';
import CreatableSelect from './components/CreatableSelect';
import Form from './components/Form';
import FormMask from './components/FormMask';
import FlashMessage from './components/flash-message';
import IconButton from './components/IconButton';
import LoadingBox from './components/LoadingBox';
import Pagination from './components/Pagination';
import PrescriptionButton from './components/PrescriptionButton';
import Range from './components/Range';
import ResponsibilityWarnModal from './components/ResponsibilityWarnModal';
import Select from './components/Select';
import Tab from './components/Tab';
import Tabs from './components/Tabs';
import ButtonLoading from './components/buttons/button-loading/';
import Layout from './components/Layout';
import InputField from './components/inputs/input-field';
import DatePick from './components/inputs/date-pick';
import ReactSelect from './components/inputs/react-select';
import ToastLoadingTable from './components/loading/toast-loading-table';
import ProtectedModule, { EPlanModules } from './components/protected/protected-module';
import ConfirmModal from './components/modals/ConfirmModal';
import Badge from './components/badge';

type TComponentsOld = {
  AsyncSelect: typeof AsyncSelect;
  Accordion: typeof Accordion;
  Avatar: typeof Avatar;
  Badge: typeof Badge;
  Button: typeof Button;
  ButtonLoading: typeof ButtonLoading;
  BreadCrumb: typeof BreadCrumb;
  Card: typeof Card;
  CreatableSelect: typeof CreatableSelect;
  Form: typeof Form;
  FormMask: typeof FormMask;
  FlashMessage: typeof FlashMessage;
  IconButton: typeof IconButton;
  LoadingBox: typeof LoadingBox;
  Layout: typeof Layout;
  Pagination: typeof Pagination;
  PrescriptionButton: typeof PrescriptionButton;
  Range: typeof Range;
  ResponsibilityWarnModal: typeof ResponsibilityWarnModal;
  Select: typeof Select;
  Tab: typeof Tab;
  Tabs: typeof Tabs;
  InputField: typeof InputField;
  DatePick: typeof DatePick;
  ReactSelect: typeof ReactSelect;
  ToastLoadingTable: typeof ToastLoadingTable;
  ProtectedModule: typeof ProtectedModule;
  ConfirmModal: typeof ConfirmModal;

  // Enums
  EPlanModules: typeof EPlanModules;
};

const ComponentsOld: TComponentsOld = {
  AsyncSelect,
  Accordion,
  Avatar,
  Badge,
  Button,
  ButtonLoading,
  BreadCrumb,
  Card,
  CreatableSelect,
  Form,
  FormMask,
  FlashMessage,
  IconButton,
  LoadingBox,
  Layout,
  Pagination,
  PrescriptionButton,
  ProtectedModule,
  Range,
  ResponsibilityWarnModal,
  Select,
  Tab,
  Tabs,
  InputField,
  DatePick,
  ReactSelect,
  ToastLoadingTable,
  ConfirmModal,

  // Enums
  EPlanModules,
};

export default ComponentsOld;
