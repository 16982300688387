import React, { useEffect, useState } from 'react'
import {
    FormGroupStyled,
    FormLabelStyled,
    FormControlStyled,
    FormTextStyled,
    FormFeedbackStyled,
 } from './styles'

const FormMask = ({ label, text, feedback, mask, inputRef, ...props }) => {

    const [value, setValue] = useState(props.value || '')

    useEffect(() => {
        !value && props.value && setValue(props.value)
    }, [props.value])

    function internalChange(el) {
        if (!mask) {
            props.onChange && props.onChange(el)
            setValue(el.target.value)
            return
        }

        const value = el.target.value //.replace(/[^A-Za-z0-9]+/g, '')
        el.target.value = value.slice(0, mask.length)
            .split('')
            .reduce((acc, char, index) => {
                if (mask.split('').includes(char))
                    char = ''

                if (mask[index] !== '#')
                    acc += mask[index]

                acc += char
                return acc
            }, '')
        setValue(el.target.value)
        props.onChange && props.onChange(el)
    }

    return (
        <>
            <FormGroupStyled>
                {label && (
                    <FormLabelStyled htmlFor={props.id}>{label}</FormLabelStyled>
                )}
                <FormControlStyled
                    {...props}
                    type='text'
                    value={value}
                    onChange={internalChange}
                />
                {text && (
                    <FormTextStyled muted>{text}</FormTextStyled>
                )}
                <FormFeedbackStyled type="invalid">{feedback || 'Campo obrigatório'}</FormFeedbackStyled>
            </FormGroupStyled>
        </>
    )
}

export default FormMask