import { devices } from "../../../../shared/styles/sizesMediaQueries";
import styled from "styled-components";
import BVUtilsOld from "@bv/utils-old";
const { colors } = BVUtilsOld;

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  a,
  button {
    color: ${colors.primaryColor};
    text-decoration-line: underline;
  }

  small {
    color: #595a5c;
  }

  span {
    color: #595a5c;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
  }

  @media ${devices.tablet} {
    align-items: baseline;
  }
`;
